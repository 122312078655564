import * as React from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailchimpForm from "../subscribe/mailchimp";
import logo from "../../../images/assets/title.png";

import pages from "../../../data/pages.json";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="main-toolbar">
          <Box
            className="logo-container"
            sx={{ flexGrow: 1, display: { xs: "flex" } }}
          >
            <Link className="header-logo-link" to="/">
              <img src={logo} className="app-logo" alt="logo" />
            </Link>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
              className="menu-icon-button"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              id="menu-appbar"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <div className="menu-app-bar">
                <Link className="footer-logo-link" to="/">
                  <img src={logo} className="footer-logo" alt="logo" />
                </Link>
                {pages.map((page) => (
                  <Link
                    className="mobile-menu-link"
                    to={page.link}
                    key={page.name}
                  >
                    <Button
                      onClick={handleCloseNavMenu}
                      color="primary"
                      size="large"
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginBottom: "15px",
                        fontSize: "16px",
                        borderWidth: "3px",
                        borderRadius: "0px",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))}
                <Button
                  href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: "15px",
                    fontSize: "16px",
                    borderWidth: "3px",
                    borderRadius: "0px",
                  }}
                  target="_blank"
                >
                  Buy Tickets Now
                </Button>
                <Button
                  href="https://www.manchesterpsychfest.com/"
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: "15px",
                    fontSize: "16px",
                    borderWidth: "3px",
                    borderRadius: "0px",
                  }}
                  target="_blank"
                >
                  Manchester Psych Fest
                </Button>
                <Button
                  href="https://brightonpsychfest.com/"
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: "15px",
                    fontSize: "16px",
                    borderWidth: "3px",
                    borderRadius: "0px",
                  }}
                  target="_blank"
                >
                  Brighton Psych Fest
                </Button>

                <div className="footer-social-container">
                  <h3>Get in touch</h3>
                  <a
                    className="email-link"
                    href="mailto:info@edinburghpsychfest.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@edinburghpsychfest.com
                  </a>

                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/edinburghpsychfest/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href="https://www.instagram.com/edinburghpsychfest/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://twitter.com/edinburgh_psych"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </div>
                </div>

                <div className="subscribe-inner">
                  <h3>Join our mailing list</h3>
                  <MailchimpForm />
                </div>
              </div>
            </Drawer>
          </Box>
          <Box
            className="menu-desktop"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {pages.map((page) => (
              <Link key={page.name} className="menu-link" to={page.link}>
                <Button
                  onClick={handleCloseNavMenu}
                  color="secondary"
                  size="large"
                  variant="outlined"
                  sx={{
                    marginLeft: "15px",
                    textAlign: "center",
                    fontSize: "16px",
                    borderWidth: "3px",
                    borderRadius: "0px",
                  }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}

            <Button
              href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginLeft: "15px",
                textAlign: "center",
                fontSize: "16px",
                borderWidth: "3px",
                borderRadius: "0px",
              }}
              target="_blank"
              className="header-button"
            >
              Buy Tickets
            </Button>
            <Button
              href="https://www.manchesterpsychfest.com/"
              className="header-button"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginLeft: "15px",
                textAlign: "center",
                fontSize: "16px",
                borderWidth: "3px",
                borderRadius: "0px",
              }}
              target="_blank"
            >
              Manchester Psych Fest
            </Button>
            <Button
              href="https://brightonpsychfest.com/"
              className="header-button"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginLeft: "15px",
                textAlign: "center",
                fontSize: "16px",
                borderWidth: "3px",
                borderRadius: "0px",
              }}
              target="_blank"
            >
              Brighton Psych Fest
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
