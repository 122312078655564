import { Button, Container } from "@mui/material";
import lineup from "../../images/assets/WEB.png";

import info from "../../images/assets/info.png";
import gifEpf from "../../images/logo/EPF 2024 GIF - LANDSCAPE.gif";

function Home() {
  return (
    <div className="home">
      <div className="home-tickets">
        <Container maxWidth="xl">
      
      <div className="border-lineup">
        
          <div className="lineup-container">
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>
          <div className="info-container">
            <div className="info-container-divs logo-div">
            <img src={gifEpf} className="gif-image" alt="lineup" />

            </div>
            <div className="info-container-divs info-div">
              <img src={info} className="info-img" alt="lineup" />
              <Button
              href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginTop: "30px",
                marginBottom: "0",
                fontSize: "24px",
                padding: "15px",
                borderWidth: "3px",
                borderRadius: "0px",
              }}
              target="_blank"
              className="ticket-header-button"
            >
              Buy Tickets
            </Button>
            </div>
          </div>
          </div>

        </Container>
      </div>
    </div>
  );
}
export default Home;
